* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--color-primary);
    border-radius: 10px;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

:root {
  --color-biciva: #aadc01;
  --color-futbolva: #60c140;
  --color-kidsva: #f28a2f;
  --color-outdoorva: #c06b45;
  --color-runningva: #3782e8;
  --color-tenisva: #e96a26;
  --color-trainingva: #9765f4;
  --color-urbanva: #60d3af;
  --color-vaypolva: #ffbe1e;
  --color-vaypol: #ffbe1e;
  --color-black: #121212;
  --color-black-70: #121212b3;
  --color-white: #b8b8b8;
  --color-white-light: #e5e5e5;
  --color-white-absolute: #fff;
  --color-white-dark: #a0a0a0;
  --color-backdrop: #000000c4;
  --color-grey: #1a1a1a;
  --color-grey-lighter: #818181;
  --color-grey-light: #313131;
  --color-grey-dark: #282828;

  /* Another Colors */
  --color-red-offer: #e20a16fa;
  --color-favorite-fully: #e20a16fa;
  --color-disable-size: #676767;
  /* Status Colors */
  --color-info: #0076d1;
  --color-success: #357e38;
  --color-error: #b42818;
  --color-warning: #e8cd30;

  /* ---Border Radius--- */
  --br-soft: 8px;
  --br-medium: 12px;
  --br-hard: 15px;

  /* ---Zindex--- */
  --z-spinner: 240;
  --z-modal: 200;
  --z-modal-close-icon: 210;
  --z-backdrop-filters: 90;
  --z-nav-fixed: 10;
  --z-content-top: 80;
  --z-content: 8;
  --z-chevron: 9;
  --z-content-bottom: 7;
  --z-background-top: 2;
  --z-background: 0;

  /* ---Breakpoints--- */
  --desktop: 1000;
  /* Variables */
  --color-text: var(--color-white);
  --color-background: var(--color-black);
  --color-background-80: var(--color-black-70);
  --color-background-container: var(--color-grey);
  --color-primary: var(--color-vaypolva);
  --color-text-on-primary: var(--color-grey);
  --color-secondary: var(--color-vaypolva);
  --color-text-on-secondary: var(--color-vaypolva);
  --color-terciary: var(--color-grey);
  --color-text-on-terciary: var(--color-grey);
  --color-text-on-transparent: var(--color-white-absolute);
  --color-text-on-transparent-disabled: var(--color-grey-light);
  --color-link-text: var(--color-vaypolva);
  --color-disabled: var(--color-vaypolva);
  --color-text-on-disabled: var(--color-vaypolva);
  --color-border-neutral: var(--color-grey-light);
  --color-navbar: var(--color-grey);
  --color-discount: var(--color-red-offer);
}

body {
  font-size: 16px;
  color: var(--color-grey);
  background-color: var(--color-black);
  overflow-x: hidden;

  & > div#__next {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  &.inactive {
    overflow: hidden;
    // margin-right: 4px;
  }
}

body.modal-open {
  overflow: hidden;
}

a {
  text-decoration: none;
  color: var(--color-grey);
}

li {
  list-style: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--color-white-light);
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232329;
  caret-color: var(--color-white-light);
}

.isContainer {
  margin: 1.5rem;
  padding: 2rem;
  border-radius: var(--br-hard);

  @media screen and (min-width: var(desktop)) {
    margin: 3rem;
    padding: 3rem;
    margin-inline: 40px;
  }
}

.isBackgrounded {
  background-color: var(--color-grey);
}

.fullWidth {
  grid-column: span 4;
}

.halfWidth {
  grid-column: span 4;

  @include for-desktop-up {
    grid-column: span 2;
  }
}

.quarterWidth {
  grid-column: span 2;

  @include for-desktop-up {
    grid-column: span 1;
  }
}

.scrollBarStyles {
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track-piece {
    margin-block: 0.5rem;
    border-radius: 50px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--color-primary);
    border-radius: 10px;
  }
}
